import { render, staticRenderFns } from "./PaginationComponent.vue?vue&type=template&id=b230b97e&scoped=true&"
import script from "./PaginationComponent.vue?vue&type=script&lang=ts&"
export * from "./PaginationComponent.vue?vue&type=script&lang=ts&"
import style0 from "./PaginationComponent.less?vue&type=style&index=0&id=b230b97e&lang=less&scoped=true&"
import style1 from "./PaginationComponent.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b230b97e",
  null
  
)

export default component.exports