













































































import PaginationComponent from '@/commoncomponents/paginationcomponent/PaginationComponent';
export default PaginationComponent;
